import { navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
import { confirmVerifyEmailAddress } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
import Button from "../components/Elements/Button"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"
import { Link } from "gatsby"

const VerifyEmail = ({ location }) => {

  const { state, dispatch } = useContext(StateContext)

  const { authentication, dispatchAuthentication, runLogin } = useContext(
    AuthenticationContext
  )

  const [loading, setLoading] = useState(true)

  const [verifySuccess, setVerifySuccess] = useState(false)

  const token = state.token

  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const hash = urlParams.get("t")
    const userEmail = urlParams.get("e")

    if (!hash || !userEmail) {
      setVerifySuccess(false)
      setLoading(false)
      return
    }

    await confirmVerifyEmailAddress(token, hash, userEmail).then(async res => {
console.log(res, 'res')
      if (res.status === 200) {
        setVerifySuccess(true)
      } else {
        setVerifySuccess(false)
      }
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    if (authentication.currentUserData.isAuthenticated) {
      navigate("/")
    }
  }, [authentication])

  if (authentication.currentUserData.isAuthenticated === false && !loading) {
    return (
      <LayoutMinimal
        title={"Verify Your Email"}
        footer={"©2025 O2O. All rights reserved."}
        metaTitle={"Verify Your Email"}
      >
        {loading && <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>}
        {!loading && verifySuccess && <><p className="text-reg-16 text-center mb-[20px]">
          Thank you! Your email address has been verified successfully.
        </p>{" "}
          <p className="text-reg-16 text-center mb-[20px]">
            Accounts may be subject to an additional approval process by our team. You'll receive an email once your account has been approved.
          </p>
          <p className="text-reg-16 text-center mb-[20px]">If your account has already been approved, you may now <Link to="/login" className="text-red hover:underline">login</Link>.</p></>}
        {!loading && !verifySuccess && <><p className="text-reg-16 text-center mb-[50px]">
          Sorry, you've provided an incorrect link or this confirmation URL has expired. Please contact our support team if you need further assistance.
        </p>
        </>}
      </LayoutMinimal>
    )
  }
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
    </div>
  )
}

export default VerifyEmail
